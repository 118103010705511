/** @jsx jsx */
import {jsx} from 'theme-ui'
import {graphql, PageProps} from 'gatsby'
import {PageContext} from 'gatsbyTypes'
import DonationPage from 'pages/donate'
import {DonationQuery} from '../graphqlTypes'

interface IDonationPage extends PageProps<DonationQuery, PageContext> {}

const DonationPageTemplate: React.FC<IDonationPage> = ({data, pageContext}) => {
  return <DonationPage data={data} pageContext={pageContext} />
}
export default DonationPageTemplate

export const pageQuery = graphql`
  query DonationTemplate {
    ...DonationHeader
  }
`
